import React, {useEffect, useState} from "react";
import {loginUserClient} from "../../configurations/features/authSliceUser";
import {toastClick} from "../toast/CustomToast";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export default function ForgetPassword(){
    const [timeLeft, setTimeLeft] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [login, setLogin] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [active, setActive] = useState(false);
    const [isTimerExpired, setIsTimerExpired] = useState(false);
    const [resetField, setResetField] = useState(
        {
            code: '',
            pwd: '',
            confirmpwd: ''
        }
    );
    const [activeReset, setActiveReset] = useState(
        {
            code: false,
            pwd: false,
            confirmpwd: false
        }
    );
    // Fonction pour démarrer le compte à rebours
    const startTimer = () => {
        setIsRunning(true); // Activer le timer
        setIsTimerExpired(false); // Réinitialiser l'état "expiré"
    };
    const restartTimer = () => {
        submitForget();
    };

    useEffect(() => {
        let timer;
        if (isRunning && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsRunning(false);
            setIsTimerExpired(true);
        }
        return () => clearInterval(timer);
    }, [isRunning, timeLeft]);

    const checkLoginValue = (event) => {
        let loginName = event.target.value.toString().trim();
        let isActive = false;
        if (loginName.length < 3) {
            document.getElementById('login1').style.border= '1px solid #f20c0c';
            isActive=false;
        } else {
            setLogin(loginName);
            document.getElementById('login1').style.border= '1px solid green';
            isActive=true;
        }
        setActive(isActive)
    }

    const checkResetCodeValue = (event) => {
        let pwd = event.target.value.toString().trim();
        if (pwd.length < 3) {
            document.getElementById('resetCode').style.border= '1px solid #f20c0c';
            activeReset.code=false;
        } else {
            resetField.code = pwd;
            setResetField(resetField);
            document.getElementById('resetCode').style.border= '1px solid green';
            activeReset.code=true;
        }
        setActiveReset(activeReset)
    }

    const submitForget = () => {
        document.getElementById('preloader').classList.remove("preloader-deactivate");
        axiosInstance().get('api/auth/forget_password?login='+login)
            .then(function (response) {
                setConfirm(true);
                document.getElementById('preloader').classList.add("preloader-deactivate");
                setTimeLeft(60);
                setIsRunning(true);
            })
            .catch(function (error) {
                document.getElementById('preloader').classList.add("preloader-deactivate");
                toastClick(error.response.data.error,"error");
            });
    };

    const submitForgetPWD = (event) => {
        event.preventDefault();
        submitForget();
    };

    const submitForgetPWDConfirm = (event) => {
        event.preventDefault();
        if (Object.values(activeReset).every(value => value)) {
            document.getElementById('preloader').classList.remove("preloader-deactivate");
            let data = {
                login:login,
                code:resetField.code,
                password:resetField.pwd,
                confirm:resetField.confirmpwd
            };
            axiosInstance().post('api/auth/forget_password/confirm', data)
                .then(function (response) {
                    document.getElementById('preloader').classList.add("preloader-deactivate");
                    window.location.href='/';
                })
                .catch(function (error) {
                    document.getElementById('preloader').classList.add("preloader-deactivate");
                    toastClick(error.response.data.error,"error");
                });
        } else {
            toastClick("Champs vide","info");
        }
    };
    const checkPassWordValue = (event) => {
        let pwd = event.target.value.toString().trim();
        if (pwd.length < 3) {
            document.getElementById('pwd').style.border= '1px solid #f20c0c';
            activeReset.pwd=false;
        } else {
            resetField.pwd = pwd;
            setResetField(resetField);
            document.getElementById('pwd').style.border= '1px solid green';
            activeReset.pwd=true;
        }
        setActiveReset(activeReset)
    }
    const checkConfirmPassWordValue = (event) => {
        let pwd = event.target.value.toString().trim();
        if (pwd.length < 3) {
            document.getElementById('confirmpwd').style.border= '1px solid #f20c0c';
            activeReset.confirmpwd=false;
        } else {
            if(pwd!=resetField.pwd){
                document.getElementById('confirmpwd').style.border= '1px solid #f20c0c';
                activeReset.confirmpwd=false;
            }else {
                resetField.confirmpwd = pwd;
                setResetField(resetField);
                document.getElementById('confirmpwd').style.border= '1px solid green';
                activeReset.confirmpwd=true;
            }
        }
        setActiveReset(activeReset)
    }

    return(
        <main>
            <div className="container">
                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a className="logo d-flex align-items-center w-auto">
                                        <img src="/assets/img/logo.png" alt="" />
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Mot de Passe Oublie</h5>
                                        </div>
                                        {
                                            confirm ? <>
                                                <div className="col-12">
                                                    {timeLeft} secondes {
                                                    (timeLeft == 0) && <button className = 'btn btn-primary' onClick={submitForgetPWD}>Renvoyer le code par SMS<i/></button>
                                                }
                                                </div>
                                                <form className="row g-3 needs-validation"  onSubmit={submitForgetPWDConfirm}>

                                                    <div className="col-12">
                                                        <label htmlFor="resetCode" className="form-label">Reset Code</label>
                                                        <div className="input-group has-validation">
                                                            <input type="text" onKeyUp={checkResetCodeValue}  name="resetCode" className="form-control" id="resetCode" required={true}  />
                                                            <div className="invalid-feedback">Reset Code</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label htmlFor="pwd" className="form-label">Nouveau mot de Passe</label>
                                                        <div className="input-group has-validation">
                                                            <input type="password" onKeyUp={checkPassWordValue}  name="pwd" className="form-control" id="pwd" required={true}  />
                                                            <div className="invalid-feedback">Nouveau mot de Passe</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label htmlFor="confirmpwd" className="form-label">Confirmer le nouveau mot de Passe</label>
                                                        <div className="input-group has-validation">
                                                            <input type="password" onKeyUp={checkConfirmPassWordValue}  name="confirmpwd" className="form-control" id="confirmpwd" required={true}  />
                                                            <div className="invalid-feedback">Confirmer le nouveau mot de Passe</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <button className="btn btn-primary w-100" type="submit">Connexion</button>
                                                    </div>

                                                </form>
                                            </> : <>
                                                <form className="row g-3 needs-validation"  onSubmit={submitForgetPWD}>

                                                    <div className="col-12">
                                                        <label htmlFor="yourUsername" className="form-label">Nom d'utilisateur</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                            <input type="text" onKeyUp={checkLoginValue}  name="username1" className="form-control" id="login1" required={true}  />
                                                            <div className="invalid-feedback">Entre votre nom d'utilisateur</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <button className="btn btn-primary w-100" type="submit">Connexion</button>
                                                    </div>
                                                    <div className="col-12">
                                                        <p className="small mb-0">Se connecte  ? <a
                                                            href="/">Ici</a></p>
                                                    </div>
                                                </form>
                                            </>
                                        }




                                    </div>
                                </div>
                                <div className="credits">© Copyright <a>AfriYAN Cameroun</a>. All Rights Reserved by</div>



                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
}